/* ---------------------------------------------------
    PADDING STYLE
----------------------------------------------------- */
.p-0-point-1 {
	padding: 0.1rem !important;
}

.px-2-point-5 {
    padding-left: 0.65rem !important;
    padding-right: 0.65rem !important;
}

.px-3-point-5 {
    padding-left: 1.3rem !important;
    padding-right: 1.3rem !important;
}

.px-4-point-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.py-0-point-1 {
	padding-top: 0.1rem !important;
	padding-bottom: 0.1rem !important;
}

.py-0-point-2 {
	padding-top: 0.2rem !important;
	padding-bottom: 0.2rem !important;
}

.py-0-point-3 {
	padding-top: 0.3rem !important;
	padding-bottom: 0.3rem !important;
}

.py-0-point-35 {
	padding-top: 0.35rem !important;
	padding-bottom: 0.35rem !important;
}

.py-0-point-4 {
	padding-top: 0.4rem !important;
	padding-bottom: 0.4rem !important;
}

.py-0-point-411 {
	padding-top: 0.411rem !important;
	padding-bottom: 0.411rem !important;
}

.py-0-point-44 {
	padding-top: 0.44rem !important;
	padding-bottom: 0.44rem !important;
}

.py-2-point-5 {
	padding-top: 0.75rem !important;
	padding-bottom: 0.75rem !important;
}

.ps-4-point-5 {
	padding-left: 2rem !important;
}

.pe-2-point-5 {
	padding-right: 0.85rem !important;
}

.pe-3-point-5 {
	padding-right: 1.2rem !important;
}

.pe-4-point-5 {
	padding-right: 2rem !important;
}

.pb-6 {
	padding-bottom: 5rem !important;
}