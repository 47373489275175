/* ---------------------------------------------------
    BRAND PROFILE STYLE
----------------------------------------------------- */
.brand-details {
    bottom: -25px !important;
}

.brand-cover-photo {
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .cover-image {
        height: 100% !important;
        object-fit: cover !important;
    }

    img, .reactEasyCrop_Container {
        border-top-left-radius: 1.9rem !important;
        border-top-right-radius: 1.9rem !important;
    }
}

.brand-cover-save-section {
    background: #E5D7FC !important;
}

.brand-tab {
    margin-left: 10rem !important;
    border: 0 !important;

    button {
        border: 0 !important;
        padding: 0 !important;
        background: transparent !important;
    }

    .nav-item:hover {
        span {
            color: $primary !important;
        }
    }
}

.brand-review-user {
    width: 90px !important;
    height: 90px !important;
	object-fit: cover !important;
}

.review-action-fill {
    color: $primary !important;
}

.review-action-not-fill {
    color: #15053e !important;
}

.brand-contact-us-button {
    width: 130px !important;
    background-color: orange !important;
    border-color: orange !important;
}

.brand-analytics-tab {
    button {
        border: 0 !important;
        padding-left: 0 !important;
        background: transparent !important;
    }

    .active {
        border-bottom: 1px solid $primary !important;
    }
}

[data-bs-theme=dark] {
    .brand-cover-save-section {
        .close-button {
            color: $primary !important;
        }

        .close-button:hover {
            color: white !important;
        }
    }

    .review-action-not-fill {
        color: white !important;
    }
}