/* ---------------------------------------------------
    PRODUCT STYLE
----------------------------------------------------- */
.product-creation-image {
    width: 75px !important;
    height: 75px !important;
	object-fit: cover !important;
}

[data-bs-theme=light] {
    .product-tab {
        button {
            background: $primary !important;
            border: none !important;
        }

        button:hover {
            span {
                color: white !important;
            }
        }

        .active {
            span {
                color: white !important;
            }
        }
    }
}

[data-bs-theme=dark] {
    .product-tab {
        button {
            background: $primary !important;
            border: none !important;
        }

        button:hover {
            span {
                color: #15053e !important;
            }
        }

        .active {
            span {
                color: #15053e !important;
            }
        }
    }
}

.product-image {
    width: 345px !important;
    height: 345px !important;
}

.product-preview-image {
    width: 45px !important;
    height: 45px !important;
    object-fit: contain !important;
}

.recommend-modal-image {
    width: 2.5em !important;
    height: 2.5em !important;
}

.product-comparison-div {
    height: 700px !important;
}

.product-comparison-image {
    width: 100% !important;
    height: 230px !important;
    object-fit: cover !important;
}

.product-comparison-feature {
    height: 182px !important;
}

.product-comparison-dropdown {
    .btn:active{ 
        background-color: transparent !important;
        border: 0 !important;
    }
}

.product-comparison-button {
    max-width: 150px !important;
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(3 * 1.5 * 14px);
  font-size: 14px;
  line-height: 1.5;
}

.open {
  -webkit-line-clamp: unset;
  max-height: none;
}