/* ---------------------------------------------------
    USER PROFILE STYLE
----------------------------------------------------- */
.user-cover-photo {
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .cover-image {
        height: 100% !important;
        object-fit: cover !important;
    }

    img, .reactEasyCrop_Container {
        border-top-left-radius: 1.94rem !important;
        border-top-right-radius: 1.94rem !important;
    }
}

.user-cover-save-section {
    background: #E5D7FC !important;
}

[data-bs-theme=dark] {
    .user-cover-save-section {
        .close-button {
            color: $primary !important;
        }

        .close-button:hover {
            color: white !important;
        }
    }
}

.user-details {
    bottom: -25px !important;

    .user-profile-photo {
        width: 135px !important;
        height: 135px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }
}

.user-tab {
    margin-left: 10rem !important;
    border: 0 !important;

    button {
        border: 0 !important;
        padding: 0 !important;
        background: transparent !important;
    }

    .nav-item:hover {
        span {
            color: $primary !important;
        }
    }
}

.user-analytics-tab {
    button {
        border: 0 !important;
        padding-left: 0 !important;
        background: transparent !important;
    }

    .active {
        border-bottom: 1px solid $primary !important;
    }
}

.verify-button, .learn-more-button {
    width: 130px !important;
}

.verify-author {
    background: #67AC5B !important;
}

.support-button {
    width: 150px !important;
    color: #15053E !important;
    background: white !important;
    border-color: transparent !important;
}

.product-wishlist-div {
    background: url("https://agni.adignis.com/backend/public/uploads/assets/static/wishListBg.webp") !important;
    background-size: 100% 100%!important;
    background-repeat: no-repeat;
}

.feature-image {
    width: 100% !important;
    height: 295px !important;
    object-fit: cover !important;
}

.followers-tab {
    border: none !important;
    border-bottom: 1px solid var(--bs-border-color) !important;

    button {
        border: none !important;
        border-bottom: 1px solid !important;
        padding-left: 0 !important;
        border-color: transparent !important;
    }

    button.active {
        border-color: $primary !important;
    }
}

.brand-follow-image, .wishlist-image {
    width: 52px !important;
    height: 52px !important;
}

.recommendation-image {
    width: 16px !important;
    height: 16px !important;
}

.user-follower-list, .user-following-list, .user-product-recommendation-list, .user-follower-brand-list, .user-follower-product-wishlist {
    height: 300px !important;
    max-height: 300px !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}

.user-influence-tab-div {
    height: 445px !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;

    .tab-content {
        width: 100% !important;
    }
}

.owned-feature-product {
    height: 251px !important;
}

.matchbox-tab {
    .nav-item {
        background: #E2E3E5 !important;
    }

    button {
        border: none !important;
        border-bottom: 1px solid #667085 !important;
        border-radius: 0 !important;
    }

    li:first-child {
        border-right: 1px solid #667085 !important;
    }

    .nav-link {
        color: black !important;
    }

    .active {
        color: $primary !important;
        background: #E2E3E5 !important;
    }
}

[data-bs-theme=dark] {
    .matchbox-tab {
        .nav-item {
            background: #363545 !important;
        }

        .nav-link {
            color: white !important;
        }

        .active {
            color: $primary !important;
            background: #363545 !important;
        }
    }
}

.matchbox-card {
    img {
        width: 65px !important;
        height: 65px !important;
        aspect-ratio: auto 65 / 65 !important;
    }
}

.privacy-radio {
    input {
        display: none !important;
    }
}

/* ---------------------------------------------------
    USER SETTINGS STYLE
----------------------------------------------------- */
.two-factor-authentication-label {
    width: 160px !important;
}

// summary flag circle
.summary-flag{
    height: 16px !important;
    width: 16px !important;
}