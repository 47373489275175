/* ---------------------------------------------------
    ANIMATION STYLE
----------------------------------------------------- */
.lottie-animation {
	z-index: 4000 !important;
	background: white !important;
}

[data-bs-theme=dark] {
	.lottie-animation {
		background: #221F2E !important;
	}
}