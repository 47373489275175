/* ---------------------------------------------------
    BORDER STYLE
----------------------------------------------------- */
.rounded-bottom-2 {
	border-bottom-left-radius: 0.375rem !important;
	border-bottom-right-radius: 0.375rem !important;
}

.rounded-top-left {
    border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-top-right {
    border-top-right-radius: var(--bs-border-radius) !important;
}

.border-orange {
	border-color: orange !important;
}

.border-start-0-point-1 {
    border-left-width: 0.01px !important;
}

.border-end-0-point-1 {
    border-right-width: 0.01px !important;
}