/* ---------------------------------------------------
    LIST STYLE
----------------------------------------------------- */
.list-style-type-none {
    list-style-type: none !important;
}

.terms-list-active {
	a {
		text-decoration: underline !important;
		color: $primary !important;
	}
}