/* ---------------------------------------------------
    BLUR STYLE
----------------------------------------------------- */
.disabled-blur {
	-webkit-backdrop-filter: blur(20px) !important;
    backdrop-filter: blur(20px) !important;
}

.privacy-blur {
	-webkit-backdrop-filter: blur(150px) !important;
    backdrop-filter: blur(150px) !important;
}

.hover-blur {
	inset: 0px !important;
    opacity: 0.9 !important;
    backdrop-filter: blur(2px) !important;
	background: #F9FAFB !important;
}

.chart-blur {
    filter: blur(15px) !important;
}