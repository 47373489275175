/* ---------------------------------------------------
    CAROUSEL STYLE
----------------------------------------------------- */
.signup-carousel {
    background: $signup-carousel;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-top-left-radius: 1.25rem !important;
    border-bottom-left-radius: 1.25rem !important;
    max-height: 760px !important;

    .carousel-item {
        border-top-left-radius: 1.25rem !important;
        border-bottom-left-radius: 1.25rem !important;
    }

    .carousel-inner, .carousel-item {
        height: 100% !important;
    }

    .carousel-caption {
        position: absolute !important;
        height: 100% !important;
        right: 0% !important;
        bottom: 0 !important;
        left: 0% !important;
        z-index: 0 !important;
        padding-top: 0px !important; 
        padding-bottom: 0px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
    }
}
