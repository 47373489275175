/* ---------------------------------------------------
    BUTTON STYLE
----------------------------------------------------- */
.btn:active{
    background-color: var(--button-orange-color) !important;
    color: white !important;
    border: 1px solid var(--button-orange-color) !important;
    box-shadow: none!important;
}

.btn:disabled:active{
    background-color: $primary !important;
    border-color: var(--bs-btn-active-border-color) !important;
}

.btn-success:disabled:active, .btn-danger:disabled:active{
    background-color: var(--bs-btn-disabled-bg) !important;
}

.btn-outline-ignite {
    border-color: #EE3C29 !important;
    color: #EE3C29 !important;
    background-color: white !important;
}

.cookie-essential {
    color: var(--button-orange-color) !important;
    border-color: var(--button-orange-color) !important;
}

.cookie-essential:hover {
    background: var(--button-orange-color) !important;
    color: white !important;
}

.brand-create-button {
    color: $primary !important;
}

.brand-create-button:hover {
    color: white !important;
}

.copy-button {
    width: 90px !important;
}

.update-button {
    width: 105px !important;
}

[data-bs-theme=dark] {
    .btn-outline-primary {
        color: white !important
    }

    .brand-create-button {
        color: $primary !important;
    }

    .brand-create-button:hover {
        color: white !important;
    }
}

/* ---------------------------------------------------
    CURSOR STYLE
----------------------------------------------------- */
button:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}