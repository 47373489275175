/* ---------------------------------------------------
    ACCORDION STYLE
----------------------------------------------------- */
.accordion-button {
    background: transparent !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0 !important;
}

.accordion-button:not(.collapsed){
    box-shadow: none;
}

.accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}

.accordion-button::after  {
    background-image: $accordion-icon !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: $accordion-active-icon !important;
}

.custom-accordion-collapse {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x) !important;
}

.custom-industry-accordion-collapse {
    padding: var(--bs-accordion-body-padding-y) 1rem !important;
}

.user-industry-accordion-collapse {
    padding: var(--bs-accordion-body-padding-y) 1rem !important;
    padding-bottom: 0 !important;
}

.custom-accordion-button-transform {
    transform: rotate(-180deg) !important;
}

.privacy-policy-accordion, .premium-accordion {
    .accordion-header {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .accordion-button {
        color: black !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .accordion-button::after  {
        background-image: $privacy-accordion-icon !important;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: $privacy-accordion-active-icon !important;
    }
}

.premium-accordion {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .accordion-button {
        padding-right: 0 !important;
    }
}

.terms-and-condition-accordion {
    .text-header {
        color: black !important
    }

    .accordion-button {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
}

.contact-accordion {
    .accordion-button {
        font-size: 20px !important;
        font-weight: 600 !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        color: black !important;
    }
}

.category-accordion {
    .accordion-button {
        padding: 0 !important;
    }
}

[data-bs-theme=dark] {
    .privacy-policy-accordion, .premium-accordion {
        .accordion-button {
            color: white !important;
        }
    }

    .terms-and-condition-accordion {
        .text-header {
            color: #D7DFEF !important
        }
    }

    .contact-accordion {
        .accordion-button {
            color: white !important;
        }
    }
}
