/* ---------------------------------------------------
    HEIGHT STYLE
----------------------------------------------------- */
.vh-60 {
    height: 60vh !important;
}

.vh-80 {
	height: 80vh !important;
}

.h-inherit {
	height: inherit !important;
}

.h-48 {
	height: 48% !important;
}