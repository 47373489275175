/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */
a {
    color: $primary !important;
}

.primary-text:hover {
    color: $primary !important;
}

.secondary-text {
    color: var(--secondary-text-color) !important;
}

.close-text:hover {
    color: $primary !important;
}

.content-header {
    font-size: 48px !important;
}

.review-title::first-letter {
    text-transform: uppercase !important;
}

.text-orange {
	color: orange !important;
}

.text-inherit {
	color: inherit !important;
}

.text-skeleton {
	color: #EBEBEB !important;
}

.text-invoice {
	color: #222222 !important;
}

.text-invoice-secondary {
    color: #697288 !important;
}

.secondary-text-invoice {
	color: #15053E !important;
}

.brand-and-product-percentage-red {
	color: #BC1919 !important;
}

.brand-and-product-percentage-grey {
	color: #95989a !important;
}

.brand-and-product-percentage-green {
	color: #4CAF50 !important;
}

.white-break-spaces {
    white-space: break-spaces !important;
}

[data-bs-theme=dark] {
    .text-secondary {
        color: white !important;
    }

	.text-white {
		color: white !important;
	}
}