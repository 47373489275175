/* ---------------------------------------------------
    ALERT STYLE
----------------------------------------------------- */
.recommend-alert {
	display: flex !important;
    align-items: center !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;

	.btn-close {
		width: 20px !important;
		height: 20px !important;
		padding-top: 23px !important;
		padding-bottom: 0 !important;
		background-size: 20px 20px !important;
		--bs-btn-close-bg: url('../../images/common/modal-close.svg') !important;
		--bs-btn-close-opacity: 1 !important;
	}
}