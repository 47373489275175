/* ---------------------------------------------------
    POST STYLE
----------------------------------------------------- */
.post-image {
    width: 100% !important;
    object-fit: cover !important;
    min-height: min(100%, 450px);
    max-height: 550px;
}

.mulitline-input {
    textarea:focus {
        border-color: $primary !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px $primary !important;
        color: var(--bs-body-color) !important;
        outline: 0 !important;
        min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)) !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        appearance: none !important;
        border-radius: var(--bs-border-radius) !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    }
}

.post-textarea:focus {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.meta-image {
    width: 560px !important;
    height: 320px !important;
    aspect-ratio: 16/9 !important;
    object-fit: scale-down !important;
}

.post-action-fill {
    color: $primary !important;
}

.post-action-not-fill, .comment-share-action-fill {
    color: #15053e !important;
}

.comment-card {
    min-width: 250px !important;

    .comment-card-div:before{
        content: "" !important;
        position: absolute !important;
        left: -1em !important;
        top: 0% !important;
        transform: translateY(50%) !important;
        border-width: 0.7em 1em 0.7em 0 !important;
        border-color: transparent #E2E3E5 !important;
        border-style: solid !important;
    }
}

.ReactModal__Overlay--after-open {
    z-index: 2000 !important;
}

.share-button {
    width: 120px !important;
}

[data-bs-theme=dark] {
    .post-action-not-fill, .comment-share-action-fill{
        color: white !important;
    }

    .comment-card {
        .comment-card-div:before{
            border-color: transparent #363545 !important;
        }
    }
}

.previous-mention {
    a {
        color: white !important;
    }
}