/* ---------------------------------------------------
    BACKGROUND STYLE
----------------------------------------------------- */
.bg-success-toast {
	background-color: var(--success-toast-color) !important;
}

.bg-error-toast {
	background-color: var(--error-toast-color) !important;
}

.bg-info-toast {
	background-color: var(--info-toast-color) !important;
}

.bg-skeleton {
	background-color: #EBEBEB !important;
}

.bg-orange {
	background-color: #EF7309 !important;
}

[data-bs-theme=dark] {
	.bg-light {
		background-color: white !important;
	}
}

/* ---------------------------------------------------
    BACKGROUND OPACITY STYLE
----------------------------------------------------- */
.bg-opacity-05 {
  --bs-bg-opacity: 0.05 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1 !important;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2 !important;
}

.bg-opacity-70 {
  --bs-bg-opacity: 0.7 !important;
}