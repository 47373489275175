/* ---------------------------------------------------
    REACT MENTION STYLE
----------------------------------------------------- */
.react-mention-test {
	div:nth-child(2) {
		border: 1px solid whitesmoke !important;
		border-radius: 0.375rem !important;
		background: white !important;
		min-width: 300px !important;
		max-width: 300px !important;
		max-height: 220px !important;
		overflow-y: scroll !important;
	}
}

[data-bs-theme=dark] {
	.react-mention-test {
		div:nth-child(2) {
			background: #221F2E !important;
		}
	}
}

.react-mention-test__input {
	position: relative !important;
	width: 100% !important;
	resize: auto !important;
	min-height: 8.25em !important;
	padding: 0.375rem 0.75rem !important;
	display: block !important;
    appearance: none !important;
	background: transparent !important;
    background-clip: padding-box !important;
    border: 0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.react-mention-test__input:focus {
	border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.react-mention-test__highlighter {
	min-height: 8.25em !important;
	position: absolute !important;
}

.react-mention-test__suggestions {
	left: calc(-5px + 1rem) !important;
	top: 20px !important;
}