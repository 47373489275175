/* ---------------------------------------------------
    EXPLORE STYLE
----------------------------------------------------- */
.poll-vote {
    position: relative !important;
    padding-right: 0 !important;

    input {
        position: absolute !important;
        top: 4px !important;
        right: 40px !important;
    }

    label {
        width: 100% !important;
    }
}

.poll-profile-picture {
    width: 1.6em !important;
    height: 1.6em !important;
    object-fit: contain !important;
}