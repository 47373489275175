/* ---------------------------------------------------
    ALL INDUSTRY IGNITES STYLE
----------------------------------------------------- */
.all-industry-ignites-tab {
    button {
        border: 0 !important;
        padding-left: 0 !important;
        background: transparent !important;
    }

    .active {
        border-bottom: 1px solid $primary !important;
    }
}