/* ---------------------------------------------------
    TOOLTIP STYLE
----------------------------------------------------- */
.tooltip {
    line-height: 1.25 !important;
}

.tooltip-inner {
    text-align: left !important;
    padding: 0.75rem !important;
}

.brand-ignite-over-time-tooltip {
    width: 300px !important;
    --bs-tooltip-max-width: 300px !important;
}

#register-tooltip {
	.tooltip-inner {
        text-align: center !important;
    }
}

#product-tooltip {
	width: 570px !important;
	max-height: 340px !important;
	overflow-y: scroll !important;
	opacity: 1 !important;
	background: white !important;

	.tooltip-inner {
        background: white !important;
		max-width: 100% !important;
    }
}

#password-tooltip {
    .tooltip-inner {
		max-width: 320px !important;
    }
}

.restriction-tooltip {
	width: 240px !important;
    --bs-tooltip-max-width: 240px !important;
}

[data-bs-theme=dark] {
	#product-tooltip {
		background: #221F2E !important;

		.tooltip-inner {
        	background: #221F2E !important;
		}
	}
}



