/* ---------------------------------------------------
    LEFT SIDEBAR STYLE
----------------------------------------------------- */
.left-sidebar-industry-icon {
    width: 19px !important;
    height: 19px !important;
    object-fit: contain !important;
}

.left-sidebar-profile-picture {
    width: 1.6em !important;
    height: 1.6em !important;
    object-fit: contain !important;
}

.group-image {
    width: 18px !important;
    height: 18px !important;
    aspect-ratio: auto 18 / 18 !important;
}

.sun, .moon {
    margin-right: 17px !important;
}

.moon {
    color: $primary !important;
    fill: white !important;
}

.sun:hover {
    fill: white !important;
}

.moon:hover {
    color: $primary !important;
    fill: #221F2E !important;
}