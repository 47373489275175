/* ---------------------------------------------------
    SWEETALERT STYLE
----------------------------------------------------- */
.swal2-confirm {
  	display: none !important;
}

.swal2-html-container {
  	margin: 0 !important;
  	padding: 0 !important;
}

.swal2-popup {
  	padding: 0 !important;
}

.swal2-actions {
  	display: none !important;
}

.swal2-close:focus {
  	box-shadow: none !important;
}

.swal2-shown {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 0px !important;
}

.swal2-container {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 0px !important;
}

.cancel-subscription {
	.swal2-actions {
		display: flex !important;
	}

	.swal2-confirm {
		display: inline-block !important;
	}
}