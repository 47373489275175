/* ---------------------------------------------------
    SCROLLBAR STYLE
----------------------------------------------------- */
/* Browser statndard style reset here. */
* {
	/* width */
	&::-webkit-scrollbar {
		width: 5px !important;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: none !important;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888 !important;
		border-radius: 50px !important;
		visibility: visible !important;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555 !important;
		visibility: visible !important;
	}
}

.industry-picker {
	&::-webkit-scrollbar {
		width: 5px !important;
		height: 5px !important;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888 !important;
		border-radius: 50px !important;
		visibility: visible !important;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555 !important;
		visibility: visible !important;
	}
}

.recommend-brands-list, .left-sidebar-xl, .right-sidebar-xl, .right-sidebar-explore-xl, .reviews-content {
	&::-webkit-scrollbar {
		width: 5px !important;
		height: 5px !important;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		visibility: hidden !important;
	}
}

.recommend-brands-list:hover, .left-sidebar-xl:hover, .right-sidebar-xl:hover, .right-sidebar-explore-xl:hover, .reviews-content:hover {
	&::-webkit-scrollbar {
		width: 5px !important;
		height: 5px !important;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		visibility: visible !important;
	}
}