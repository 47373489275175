// All the fonts used in website has to be present here
:root {
	--primary-text-color: #fff;
	--secondary-text-color: #15053e;
	--button-orange-color: #ee3c29;
	--primay-bg-color: #fff;
	--dark-btn-bg: #5A6378;
	--dark-btn-bg-hover: #38425a;
	--button-color: #8643ff;
	--border-color: #e5e5e5;
	--google-btn-bg: #cf4331;
	--facebook-btn-bg: #3d65c4;
	--no-account-txt: #bbb8b8;
	--text-color: #1b1b1b;
	--place-holde-premuin-txt: #fff;
	--input-premium-bg-color: #ffffff1f;
	--app-bar-bg: #8643ff;
	--button-border-color: #fff;
	--plan-background-color: #655779;
	--plan-select-bg-color: #8643ff;
	--plan-header-color: #8643ff;
	--yes-no-bg-color: #8643ff;
	--steppers-circle-bg: #fff;
	--brand-name-text-color: #15053e;
	--brand-text-header-color: #8643ff;
	--brand-secondary-text-header-color: #15053e;
	--brand-secondary-text-color: #1b1b1b;
	--brand-follow-bg: #8643ff;
	--brand-bg-color: #fff;
	--brand-follow-border-color: #f0f3f5;
	--brand-border-color: #E5E8EA;
	--brand-close-bg-color: #e5e5e5;
	--brand-link-color: #6c8391;
	--news-feeds-primary-text-color: #15053e;
	--post-select-image-bg-color: #e5e5e5;
	--post-button-bg-color: #8643ff;
	--select-image-text-color: #15053e;
	--unignite-btn-bg: #4d2706;
	--ignite-bg-color: #EE3C29;
	--up-down-vote: #95989a;
	--poll-option-primary-color: #2FD8D8;
	--poll-option-secondary-color: #F46AB9;
	--warning-color: #FF8C27;
	--error-toast-color: #BC1919;
	--success-toast-color: #4CAF50;
	--info-toast-color: #1976D2;
	--warning-toast-color: #ECB50D;
	--weburl-image-preview-bg-color: #f6f6f6;
	--weburl-image-preview-border-color: #efefef;
}