/* ---------------------------------------------------
    POPOVER STYLE
----------------------------------------------------- */
.post-popover, .review-popover {
	width: 110px !important;
}

.product-popover {
	width: 160px !important;
}

.profile-popover {
	width: 285px !important;
}

.profile-popover-image {
	width: 5em !important;
	height: 5em !important;
	object-fit: cover;
}