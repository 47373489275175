/* ---------------------------------------------------
    NAVBAR STYLE
----------------------------------------------------- */
.fi {
	width: 30px !important;
    height: 22px !important;
    background-size: cover !important;
}

.navbar-button-padding {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
}

.profile-image {
    width: 40px !important;
    height: 40px !important;
	object-fit: cover !important;
    border: 1px solid transparent !important;
}

.own-brand-container {
	max-height: 140px !important;
	overflow-y: scroll !important;
}

.own-brand-div:hover {
    border: 1px solid $primary !important;
}

.brand-mention-image, .own-brand-image {
    width: 40px !important;
    height: 40px !important;
	object-fit: cover !important;
}