/* ---------------------------------------------------
    CARD STYLE
----------------------------------------------------- */
.brand-follow-card {
    max-width: 350px !important;

    img {
        width: 126px !important;
        height: 126px !important;
        max-width: 100% !important;
        max-height: 100% !important;
        object-fit: contain !important;
    }

    .close-button {
        img {
            width: 34px !important;
            height: 34px !important;
        }
    }

    .close-button:hover {
        background: transparent !important;
    }

    .close-button:active{
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }

    .brand-follow-card-body {
        width: 200px !important;
    }
}

.brand-card, .product-card, .user-card, .group-card {
    width: 150px !important;
    --bs-border-radius: 0.43rem !important;

    .card-body {
        width: 148px !important;
    }

    img {
        width: 100% !important;
        height: 140px !important;
        aspect-ratio: auto 148 / 140 !important;
        border-top-left-radius: 0.375rem !important;
        border-top-right-radius: 0.375rem !important;
    }
}

.brand-button-card, .product-button-card, .user-button-card, .group-button-card {
    width: 160px !important;
    --bs-border-radius: 0.43rem !important;

    .card-body {
        width: 158px !important;
    }

    img {
        width: 100% !important;
        height: 160px !important;
        aspect-ratio: auto 158 / 160 !important;
        border-top-left-radius: 0.375rem !important;
        border-top-right-radius: 0.375rem !important;
    }
}

.brand-skeleton-card, .product-skeleton-card {
    .image-skeleton {
        --bs-border-radius: 0.375rem !important;
    }
}

.owned-modal-product-card {
    img {
        width: 128px !important;
        height: 121px !important;
        aspect-ratio: auto 128 / 121 !important;
    }
}

.brand-follow-card:hover, .brand-card:hover, .product-card:hover, .user-card:hover, .group-card:hover {
    border: 1px solid $primary !important;
    transition: all 0.3s ease-in-out 0s !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05) !important;
}

.review-modal-product-card {
    margin-right: 0 !important;
}

.brand-profile-review-image {
    width: 100px !important;
    height: 100px !important;
    aspect-ratio: auto 100 / 100 !important;
}

[data-bs-theme=dark] {
    .card-body {
        color: white !important;
    }
}

.summary-card {
	background-color: #9E69FF !important;
    min-height: 78px !important;
}