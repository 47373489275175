/* ---------------------------------------------------
    IGNITE OVER TIME STYLE
----------------------------------------------------- */
.ignite-over-time-tab {
    button:hover {
        border: none !important;
    }

    button {
        border: none !important;
    }

    .active, .active:hover {
        border-bottom: 1px solid $primary !important;
    }
}

.add-brand-button {
    width: 105px !important;
}

#ignited-brand-list, #brand-follow-list {
    max-height: 210px !important;
    overflow: auto !important;
}