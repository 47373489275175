/* ---------------------------------------------------
    GOOGLE RECAPTCHA STYLE
----------------------------------------------------- */
.google-recaptcha > div > div {
	height: 76px !important;

	iframe {
		border: 1px solid #dee2e6 !important;
		border-radius: 0.375rem !important;
	}
}

[data-bs-theme=dark] {
	.google-recaptcha > div > div {
		iframe {
			border: 1px solid #525252 !important;
			border-radius: 0.375rem !important;
		}
	}
}

.google-recaptcha > div > div > div {
	height: 76px !important;
	background: transparent !important;
}