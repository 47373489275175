/* ---------------------------------------------------
    SKELETON STYLE
----------------------------------------------------- */
.input-skeleton {
	height: 38px !important;
}

.switch-input-skeleton {
	width: 32px !important;
	height: 12px !important;
}

.div-skeleton {
	height: 75px !important;
}

.p-skeleton {
	width: 100px !important;
	height: 20px !important;
}

.span-skeleton {
	width: 100px !important;
	height: 10px !important;
}

.sentence-skeleton {
	height: 10px !important;
}

.tab-skeleton {
	width: 50px !important;
	height: 10px !important;
}

.time-skeleton {
	width: 80px !important;
	height: 10px !important;
}

.brand-card-image-skeleton, .product-card-image-skeleton, .user-card-image-skeleton, .group-card-image-skeleton {
	width: 148px !important;
	height: 136px !important;
}

.brand-follow-card-image-skeleton {
	width: 126px !important;
	height: 126px !important;
}

.profile-image-skeleton {
	width: 40px !important;
	height: 40px !important;
}

.button-skeleton {
	width: 115px !important;
	height: 38px !important;
}

.ignite-button-skeleton {
	width: 98px !important;
	height: 34px !important;
}

.brand-follow-button-skeleton {
	width: 80px !important;
	height: 30px !important;
}

.brand-header-follow-button-skeleton {
	width: 98px !important;
	height: 34px !important;
}

.brand-follow-close-button-skeleton {
	width: 30px !important;
	height: 30px !important;
}

.post-image-skeleton {
	height: 380px !important;
}

.post-action-skeleton {
	width: 20px !important;
}

.post-action-button-skeleton {
	width: 60px !important;
	height: 15px !important;
}

.comment-compose-skeleton {
	height: 24px !important;
}

.comment-compose-button-skeleton {
	width: 24px !important;
	height: 24px !important;
}

.verify-skeleton {
	width: 17px !important;
	height: 17px !important;
}

.review-compare-button-skeleton {
	width: 60px !important;
	height: 25px !important;
}

.kebab-icon-skeleton {
	width: 6px !important;
	height: 16px !important;
}

.feature-card-image-skeleton {
	width: 267px !important;
	height: 291px !important;
}

.feature-card-brand-name-skeleton {
	height: 39px !important;
}

.brand-confidence-number-skeleton {
	width: 200px !important;
	height: 84px !important;
}

.brand-confidence-button-skeleton {
	height: 38px !important;
}

.brand-confidence-result-skeleton {
	width: 80px !important;
	height: 18px !important;
}

.sub-industry-skeleton {
	width: 80px !important;
	height: 30px !important;
}

.verify-skeleton {
	width: 13px !important;
	height: 13px !important;
}

.brand-profile-review-image-skeleton {
	width: 90px !important;
	height: 90px !important;
}

.brand-profile-review-text-skeleton {
	width: 50px !important;
	height: 10px !important;
}

.brand-profile-review-count-skeleton {
	width: 40px !important;
	height: 10px !important;
}

.review-vote-skeleton {
	width: 12px !important;
	height: 15px !important;
}

.add-brand-button-skeleton {
	width: 100% !important;
	height: 34px !important;
}

.own-brand-image-skeleton {
    width: 40px !important;
    height: 40px !important;
}

.product-name-skeleton, .product-review-type-skeleton {
	width: 100px !important;
	height: 27px !important;
}

.product-image-skeleton {
    width: 345px !important;
    height: 345px !important;
}

.product-preview-image-skeleton {
    width: 45px !important;
    height: 45px !important;
}

.product-review-number-skeleton {
	width: 150px !important;
	height: 87px !important;
}

.brand-follow-image-skeleton, .wishlist-image-skeleton {
    width: 52px !important;
    height: 48px !important;
}

.user-followers-brand-follow-button-skeleton {
	width: 90px !important;
    height: 34px !important;
}

.recommend-modal-image-skeleton {
    width: 2.5em !important;
    height: 2.5em !important;
}

.recommend-button-skeleton {
	width: 133px !important;
	height: 38px !important;
}

.product-details-button-skeleton {
	width: 115px !important;
	height: 34px !important;
}

.benefit-text-skeleton {
	height: 13px !important;
}

.update-image-skeleton {
	width: 75px !important;
	height: 71px !important;
}

.poll-title-skeleton {
	width: 100px !important;
	height: 21px !important;
}

.poll-radio-skeleton {
	height: 28px !important;
}

.poll-vote-button-skeleton {
	width: 132px !important;
	height: 38px !important;
}

.product-review-tab-title-skeleton {
	width: 72px !important;
    height: 20px !important;
}

.brand-review-tab-title-skeleton {
	width: 57px !important;
    height: 20px !important;
}

.influence-follower-tab-title-skeleton {
	width: 85px !important;
    height: 17px !important;
}

.influence-following-tab-title-skeleton {
	width: 89px !important;
    height: 17px !important;
}

.influence-tab-badge-skeleton {
	width: 22px !important;
    height: 16px !important;
}

.influence-brand-tab-title-skeleton, .influence-wishlist-tab-title-skeleton {
	width: 143px !important;
    height: 17px !important;
}

.industry-image-skeleton {
	width: 19px !important;
	height: 19px !important;
}

.matchbox-card-image-skeleton {
	width: 65px !important;
    height: 61px !important;
}

.ignite-icon-skeleton {
	width: 16px !important;
    height: 16px !important;
}

.ignite-number-skeleton {
	width: 28px !important;
    height: 18px !important;
}

.subscribe-ignite-skeleton {
	width: 110px !important;
    height: 12px !important;
}

.matchbox-span-skeleton {
	width: 100px !important;
	height: 10px !important;
}

.package {
	&-icon-skeleton {
		width: 46px !important;
		height: 42px !important;
	}

	&-name-skeleton {
		width: 46px !important;
		height: 32px !important;
	}

	&-card-name-skeleton {
		width: 46px !important;
		height: 20px !important;
	}

	&-price-skeleton, &-final-price-skeleton {
		width: 168px !important;
		height: 69px !important;
	}

	&-plan-skeleton {
		width: 105px !important;
		height: 23px !important;
	}

	&-feature {
		&-header-skeleton {
			width: 150px !important;
			height: 23px !important;
		}

		&-list {
			&-icon-skeleton {
				width: 24px !important;
				height: 24px !important;
			}

			&-title-skeleton {
				height: 20px !important;
			}
		}
	}

	&-customizer {
		&-title-skeleton {
			width: 140px !important;
			height: 20px !important;
		}

		&-card-title-skeleton {
			width: 100px !important;
			height: 12px !important;
		}

		&-button-skeleton {
			height: 34px !important;
		}
	}
}

.feature-title-accordion-skeleton {
	height: 20px !important;
}

.metric {
	&-title-skeleton {
		width: 105px !important;
		height: 23px !important;
	}
	
	&-description-tooltip-skeleton {
		width: 12px !important;
    	height: 12px !important;
	}

	&-usages-skeleton {
		width: 10px !important;
		height: 23px !important;
	}

	&-input-skeleton {
		height: 92px !important;
	}
}

.summary {
	&-title-skeleton {
		width: 120px !important;
		height: 10px !important;
	}

	&-count-skeleton {
		width: 50px !important;
		height: 10px !important;
	}
}
