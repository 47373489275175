/* ---------------------------------------------------
    MODAL STYLE
----------------------------------------------------- */
.auth-modal {
    .logo{
        max-width: 180px !important;
        margin: 0 auto 10px !important;
        object-fit: contain !important;
    }

    form {
        max-width: 304px !important;
        margin: 0 auto !important;
    }
}

.confirmation-image {
    width: 40px !important;
    height: 40px !important;
}

.cropper-border {
    border: 2px dashed $primary !important;
    border-radius: 1.25rem !important;
}

.brand-claim-modal {
    .logo{
        max-width: 180px !important;
        margin: 0 auto 10px !important;
        object-fit: contain !important;
    }

    form {
        max-width: 300px !important;
        margin: 0 auto !important;

        img {
            width: 64px !important;
            height: 64px !important;
            object-fit: cover !important;
        }
    }
}

.brand-review-modal-image {
    width: 80px !important;
    height: 80px !important;
    object-fit: cover !important;
}

.search-item-review-modal {
    .content-list {
        max-height: 350px !important;
        overflow-y: scroll !important;
    }
}

.add-photo-button {
    min-width: 135px !important;
}

.recovery-alert-modal {
    .modal-content {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
}

.modal-open {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding-right: 0px !important;
}

.modal-content {
    border: none !important;
}

.modal-header, .offcanvas-header {
    .btn-close{
        background-image: url("../../images/common/modal-close.svg") !important;
        background-repeat: no-repeat;
        background-size: 30px 30px !important;
        background-color: transparent !important;
        box-shadow: none !important;
        margin-right: 0 !important;
        padding-top: 1rem !important;
    }
}

.modal-header {
    color: #15053E !important;
}

[data-bs-theme=dark] {
    .modal-header {
        color: white !important;
    }
}