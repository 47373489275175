$primary: #8643ff;
$danger: #BC1919 ;
$accordion-icon: url('../../images/common/accordion-plus.svg');
$accordion-active-icon: url('../../images/common/accordion-plus.svg');
$privacy-accordion-icon: url('../../images/common/privacy-accordion-plus.svg');
$privacy-accordion-active-icon: url('../../images/common/privacy-accordion-minus.svg');
$slick-prev-icon: url('../../images/common/slick-prev.svg');
$slick-next-icon: url('../../images/common/slick-next.svg');
$slick-prev-dark-icon: url('../../images/common/slick-dark-prev.svg');
$slick-next-dark-icon: url('../../images/common/slick-dark-next.svg');
$swiper-prev-icon: url('../../images/common/swiper-prev.svg');
$swiper-next-icon: url('../../images/common/swiper-next.svg');
$swiper-prev-dark-icon: url('../../images/common/swiper-dark-prev.svg');
$swiper-next-dark-icon: url('../../images/common/swiper-dark-next.svg');
$signup-carousel: url('https://agni.adignis.com/backend/public/uploads/assets/static/signupBg.webp');

[data-bs-theme=light] {
    .card {
        --bs-card-color:  #15053E !important;
    }
    & {
        --bs-primary: #8643ff !important;
        --bs-danger: #BC1919 !important;
        --bs-dark-rgb: 21, 5, 62 !important;
        --bs-primary-rgb: 134, 67, 255 !important;
        --bs-danger-rgb: 188, 25, 25 !important;
        --bs-primary-text-emphasis: #361b66 !important;
        --bs-danger-text-emphasis: #4b0a0a !important;
        --bs-primary-bg-subtle: #e7d9ff !important;
        --bs-danger-bg-subtle: #f2d1d1 !important;
        --bs-primary-border-subtle: #cfb4ff !important;
        --bs-danger-border-subtle: #e4a3a3 !important;
        --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)) !important;
        --bs-tertiary-color: #dee2e680 !important;
        --bs-link-color: #8643ff !important;
        --bs-link-color-rgb: 134, 67, 255 !important;
        --bs-link-hover-color: #6b36cc !important;
        --bs-link-hover-color-rgb: 107, 54, 204 !important;
        --bs-border-color-translucent: rgba(0, 0, 0, 0.175) !important;
        --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
        --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
        --bs-focus-ring-color: rgba(134, 67, 255, 0.25) !important;
        --bs-form-invalid-color: #BC1919 !important;
        --bs-form-invalid-border-color: #BC1919 !important;
    }
}

[data-bs-theme=dark] {
    & {
        --bs-primary: #8643ff !important;
        --bs-danger: #BC1919 !important;
        --bs-body-bg: #221F2E !important;
        --bs-secondary-rgb: 133, 142, 160 !important;
        --bs-white-rgb: 34, 31, 46 !important;
        --secondary-text-color: white !important;
        --bs-body-color: #858EA0 !important;
        --bs-dark-rgb: 255, 255, 255 !important;
        --bs-primary-text-emphasis: #D7DFEF !important;
        --bs-light-rgb: 47, 43, 63 !important;
        --bs-btn-hover-bg: #8643FF !important;
        --bs-primary-rgb: 134, 67, 255 !important;
        --bs-danger-rgb: 188, 25, 25 !important;
        --bs-primary-text-emphasis: #361b66 !important;
        --bs-danger-text-emphasis: #4b0a0a !important;
        --bs-primary-bg-subtle: #e7d9ff !important;
        --bs-danger-bg-subtle: #f2d1d1 !important;
        --bs-primary-border-subtle: #cfb4ff !important;
        --bs-danger-border-subtle: #e4a3a3 !important;
        --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)) !important;
        --bs-secondary-color: #dee2e6bf !important;
        --bs-tertiary-color: #dee2e680 !important;
        --bs-link-color: #8643ff !important;
        --bs-link-color-rgb: 134, 67, 255 !important;
        --bs-link-hover-color: #6b36cc !important;
        --bs-link-hover-color-rgb: 107, 54, 204 !important;
        --bs-border-color-translucent: rgba(0, 0, 0, 0.175) !important;
        --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
        --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
        --bs-focus-ring-color: rgba(134, 67, 255, 0.25) !important;
        --bs-form-invalid-color: #BC1919 !important;
        --bs-form-invalid-border-color: #BC1919 !important;
    }
}
